import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Passen Sie das Layout des Karriereportals an Ihr CI an",
  "description": "Diese Möglichkeiten bestehen zur Anpassung des Karriereportals",
  "author": "Jochen Ritscher",
  "categories": ["settings"],
  "date": "2021-07-30T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Module`}</h2>
    <h3>{`Hauptmenü`}</h3>
    <p>{`Das inriva Karriereportal bietet ein Standard-Menü, das die wichtigsten Funktionen für alle Gerätearten darstellt. Es ist darüber hinaus möglich das Original-Navigationsmenü Ihrer Website abzurufen und darzustellen.`}</p>
    <h3>{`Stellenanzeigen Listenkopf`}</h3>
    <p>{`Auswahl der Texte, Sortierspalten und Feldbezeichnungen`}</p>
    <h3>{`Stellenanzeigen Listenkörper`}</h3>
    <p>{`Darstellung der Liste in Schrift und Form`}</p>
    <h3>{`Karte`}</h3>
    <p>{`Die mit den ausgeschriebenen Stellen verknüpften Standorte können auf einer Bing Maps Karte dargestellt werden. Es bestehen die von Microsoft vorgegebenen Anpassungsmöglichkeiten:`}</p>
    <img src="/images/settings/tab-attribute.jpg" alt="gdpr" style={{
      "width": "100%"
    }} />
    <p>{`Weitere Informationen siehe `}<a parentName="p" {...{
        "href": "http://www.bing.com/api/maps/sdkrelease/mapcontrol/isdk"
      }}>{`http://www.bing.com/api/maps/sdkrelease/mapcontrol/isdk`}</a></p>
    <h3>{`Video`}</h3>
    <p>{`Im Videobereich können Sie ein beliebiges Employer Brand Video einbinden. Als Quellen können hier beispielsweise Youtube oder Vimeo, sowie Ihr eigener Videoservice dienen. Es wird lediglich ein Einbettungscode benötigt. Beispiel YouTube: `}<a parentName="p" {...{
        "href": "https://support.google.com/youtube/answer/171780?hl=de"
      }}>{`https://support.google.com/youtube/answer/171780?hl=de`}</a></p>
    <h3>{`Fußbereich`}</h3>
    <p>{`Der inriva-Fußbereich bietet die Möglichkeit alle relevanten Informationen inklusive Impressum und Datenschutzbedingungen entsprechend Ihrer Website anzuzeigen. Es ist darüber hinaus möglich das Original-Fußbereich Ihrer Website abzurufen und darzustellen.`}</p>
    <h2>{`Stil`}</h2>
    <h3>{`Bulma`}</h3>
    <p>{`Das inriva Karriereportal baut auf dem CSS Rahmenwerk „Bulma“ auf. Bulma ist ein beliebtes modernes Framework mit HTML und CSS  für die Entwicklung von anpassungsfähigen Projekten für das moderne Web. Bootstrap beinhaltet ein anpassungsfähiges, Mobile-First-basiertes, fließendes Rastersystem, das bis zu 12 Spalten über verschiedene Geräte- oder Viewport-Größen skaliert. Dabei sind vordefinierte Klassen für einfache Layout-Optionen, sowie umfangreiche Mixins für die Erstellung von semantischeren Layouts.
Rastersysteme werden dafür verwendet, Seitenlayouts mit einer Reihe von Zeilen und Spalten zu erstellen, die deine Inhalte beherbergen. Hier finden Sie weitere Informationen darüber, wie das Bulma-Rastersystem funktioniert:
`}<a parentName="p" {...{
        "href": "https://bulma.io/"
      }}>{`https://bulma.io/`}</a></p>
    <h3>{`Rastersystem`}</h3>
    <p>{`Zeilen (.rows) müssen für korrekte Ausrichtung und Abstände in einem .container (fixierte Breite) oder .container-fluid (ganze Breite) platziert werden.
Verwende Zeilen, um horizontale Gruppen von Spalten zu erstellen.
Inhalt sollte in Spalten platziert werden und nur Spalten dürfen direkte Kinder von Zeilen sein.
Vordefinierte Raster-Klassen wie .row und .col-xs-4 sind verfügbar für die schnelle Erstellung von Layouts.
Spalten erzeugen "Furchen" (Lücken zwischen Spalten-Inhalt) über padding. Dieser Innenabstand wird in Zeilen von der ersten und letzten Spalte über negativen Außenabstand wieder abgezogen.
Raster-Spalten werden definiert, indem die Anzahl von zwölf verfügbaren Spalten angegeben wird, über die die Raster-Spalte gelegt werden soll. Zum Beispiel können drei gleich große Spalten mit drei .col-xs-4 erstellt werden.
Falls mehr als 12 Spalten in einer einzigen Zeile platziert werden, wird jede Gruppe mit Extra-Spalten als eine Einheit in eine neue Zeile rutschen.
Rasterklassen beziehen sich auf Geräte mit Bildschirmbreiten, die genau so groß oder größer als die Umbruchpunkt-Größen sind. Sie überschreiben Rasterklassen, die sich auf kleinere Geräte beziehen. Wenn z.B. eine .col-md-`}<em parentName="p">{`-Klasse auf ein Element angewendet wird, wird sich dies daher nicht nur auf die Stile auf mittleren Geräten auswirken, sondern auch auf diejenigen auf großen Geräten, es sei denn es gibt außerdem eine .col-lg-`}</em>{`-Klasse.
Weitere Informationen: `}<a parentName="p" {...{
        "href": "http://holdirbootstrap.de/css/"
      }}>{`http://holdirbootstrap.de/css/`}</a></p>
    <h3>{`Style Einstellungen (Standard)`}</h3>
    <p>{`Folgende Optionen können im Standard gesetzt werden:`}</p>
    <img src="/images/settings/tab-style.jpg" alt="Style Einstellungen" style={{
      "width": "100%"
    }} />
    <h3>{`Style Sheets (Individuell)`}</h3>
    <p>{`Das gesamte Frontend kann mittels Cascading Style Sheets (CSS) von Ihnen individuell auf Ihre Anforderungen angepasst werden. Sie verwenden hiermit die vorgegebene Struktur des Portals aber beeinflussen die Darstellung. Eine zur Verfügung gestellte CSS-Datei kann durch inriva im System importiert werden. Bitte beachten Sie dass durch eigene Anpassung verursachte Darstellungsfehler nur kostenpflichtig behoben werden können.`}</p>
    <h3>{`Header Image`}</h3>
    <p>{`Auf der Startseite können Sie im Kopfbereich ein Foto einfügen um Ihre Employer Brand darzustellen. Dieses kann im Backend eingestellt werden.`}</p>
    <h3>{`Logo`}</h3>
    <p>{`Ihr Firmenlogo können Sie im Backend selbst hochladen und einstellen. Es sollte eine Mindestbreite von 300 Pixeln aufweisen, das es je nach Bildschirmauflösung in verschiedenen Größen dargestellt wird.`}</p>
    <h2>{`Individuelle Anpassung`}</h2>
    <p>{`Das Standard Layout kann komplett an Ihr CI angepasst werden. Sprechen Sie uns an unter `}<a parentName="p" {...{
        "href": "mailto:mail@inriva.com"
      }}>{`mail@inriva.com`}</a>{` `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      